<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="record.StartDate"
              label="Start Day"
              single-line
              prepend-icon="mdi-calendar"
              readonly
              :disabled="record.activatable_type == 1 ? true : false"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="record.StartDate"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="record.StartTime"
              label="Start Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="record.activatable_type == 1 ? true : false"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.StartTime"
            full-width
            @click:minute="$refs.menu.save(time)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="record.Hours"
          label="Hours"
          type="number"
          min="0"
          max="72"
          step="1"
          :disabled="record.activatable_type == 1 ? true : false"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="record.Minutes"
          label="Minutes"
          type="number"
          min="0"
          max="59"
          step="1"
          :disabled="record.activatable_type == 1 ? true : false"
        ></v-text-field>
      </v-col>

      <!-- <v-col cols="4">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="record.EndTime"
              label="End Time"
              outlined
              dense
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="record.EndTime"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-checkbox
          outlined
          dense
          v-model="record.Enabled"
          label="Enabled"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="record"
          outlined
          dense
          v-model="record.FieldsTemplate"
          :items="templates"
          item-text="Name"
          item-value="id"
          :disabled="record.activatable_type == 1 ? true : false"
          label="Form Field Template"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          outlined
          dense
          :disabled="record.activatable_type == 1 ? true : false"
          v-model="record.Type"
          label="Company wide"
        ></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-if="record.creator?.name"
          v-model="record.creator.name"
          label="creator"
          type="text"
          readonly
          :disabled="record.activatable_type == 1 ? true : false"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-autocomplete
          :disabled="record.activatable_type == 1 ? true : false"
          outlined
          dense
          v-model="record.gates"
          :items="gates"
          item-text="Name"
          item-value="id"
          label="Gate"
          multiple
          chips
          small-chips
          deletable-chips
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :disabled="record.activatable_type == 1 ? true : false"
          outlined
          dense
          v-model="record.users"
          :items="users"
          item-text="name"
          item-value="id"
          label="Users"
          multiple
          chips
          small-chips
          deletable-chips
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    templates: [],
    users: [],
    gates: [],
    mandatory: true,
    status: true,
    menu2: false,
    time: "",
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },

  methods: {
    init(val) {
      console.log(val, "initial");
      this.record = { ...val };
    },

    sendData() {
      this.loader = true;
      console.log(this.record);
      this.$emit("data", this.record, () => {
        this.loader = false;
      });
    },
    getFieldTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/fields_template`)
        .then((res) => {
          console.log(res);
          self.templates = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gates`)
        .then((res) => {
          self.gates = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          console.log(res);
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getFieldTemplates();
    this.getGates();
    this.getUsers();
  },
};
</script>